import { createContext } from 'react';

export interface ToastContextProps {
  showToast: (
    message: string,
    type: 'success' | 'error' | 'warning' | 'info',
    options?: { duration?: number; closable?: boolean; details?: string; onDetailsClick?: () => void }
  ) => void;
}

export const ToastContext = createContext<ToastContextProps | undefined>(undefined);
