import { colors } from '@shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { keyframes } from '@stylexjs/stylex';
import { typography } from '@shared/tokens/typography.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';

const slideIn = keyframes({
  '0%': {
    right: '-320px',
    opacity: '0',
  },
  '100%': {
    right: '20px',
    opacity: '1',
  },
});

const slideOut = keyframes({
  '0%': {
    right: '20px',
    opacity: '1',
  },
  '100%': {
    right: '-320px',
    opacity: '0',
  },
});

export const styles = stylex.create({
  container: {
    display: 'flex',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    flexDirection: 'column',
    gap: '10px',
    zIndex: '1500',
  },
  base: (index, duration) => ({
    position: 'fixed',
    bottom: `${index * 70}px`,
    right: '20px',
    padding: '8px 16px',
    borderRadius: '8px',
    gap: '32px',
    opacity: '1',
    transition: 'opacity 0.6s ease-in-out, right 0.6s ease-in-out, bottom 0.4s ease-in-out',
    height: '60px',
    animation: `${slideIn} 0.6s ease-in-out, ${slideOut} 0.6s ease-in-out ${duration / 1000}s forwards`,
    color: colors.primaryInverseText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeBody-3'],
    fontWeight: typography.fontWeighRegular,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  error: {
    backgroundColor: colors.errorBackground,
    color: colors.primaryText,
  },
  warning: {
    backgroundColor: colors.warningBackground,
  },
  success: {
    backgroundColor: colors.accentBackground,
  },
  info: {
    backgroundColor: colors.infoBackground,
  },
  details: {
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: typography['fontSizeCaption-1'],
    cursor: 'pointer',
  },
  close: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
