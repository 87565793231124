import { FC } from 'react';
export const CalendarIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1.5C4.77614 1.5 5 1.72386 5 2V3H11V2C11 1.72386 11.2239 1.5 11.5 1.5C11.7761 1.5 12 1.72386 12 2V3H12.5C13.6046 3 14.5 3.89543 14.5 5V12.5C14.5 13.6046 13.6046 14.5 12.5 14.5H3.5C2.39543 14.5 1.5 13.6046 1.5 12.5V5C1.5 3.89543 2.39543 3 3.5 3H4V2C4 1.72386 4.22386 1.5 4.5 1.5ZM3.5 4C2.94772 4 2.5 4.44772 2.5 5V5.76756C2.79417 5.59739 3.13571 5.5 3.5 5.5H12.5C12.8643 5.5 13.2058 5.59739 13.5 5.76756V5C13.5 4.44772 13.0523 4 12.5 4H3.5ZM13.5 7.5C13.5 6.94772 13.0523 6.5 12.5 6.5H3.5C2.94772 6.5 2.5 6.94772 2.5 7.5V12.5C2.5 13.0523 2.94772 13.5 3.5 13.5H12.5C13.0523 13.5 13.5 13.0523 13.5 12.5V7.5Z"
        fill="#1D252D"
      />
    </svg>
  );
};
