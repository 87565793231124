import useFetchQrCode from '@shared/hooks/useFetchQrCode';
import { QrCode } from '@shared/ui/qr-code';
import { PreloaderCircular } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { styles } from './styles';

export const QrPage: FC = () => {
  const { id } = useParams();
  const { data, fetching, error } = useFetchQrCode(id as string, 261);
  /*
 useFetchQrCode вторым аргументом принимает размер QR-кода в пикселях, 261 подогнан под иконку sminex-illustration
  */

  if (fetching)
    return (
      <div {...stylex.props(styles.preloaderContainer)}>
        <PreloaderCircular variant="secondary" />
      </div>
    );

  if (error) {
    if (error.graphQLErrors && Array.isArray(error.graphQLErrors)) {
      const errorMessage = error.graphQLErrors[0]?.message;

      if (errorMessage === 'User not found for ticket') {
        return <Navigate to="/error-for-remote" />;
      }
    }

    return <Navigate to="/error" />;
  }

  return (
    <div {...stylex.props(styles.container)}>
      {data?.getTicketQrcode && (
        <>
          <QrCode svg={data.getTicketQrcode.qr} />
          <p {...stylex.props(styles.title)}>Ваш QR</p>
          <p {...stylex.props(styles.subTitle)}>Для прохода на мероприятие предъявите его организаторам</p>
        </>
      )}
    </div>
  );
};
