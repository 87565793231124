import { ConfirmContext, ConfirmProvider } from '@app/confirm-context';
import { useContext } from 'react';

export const useConfirm = (): ConfirmProvider => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('useConfirm must be used within an ConfirmContext');
  }
  return context;
};
