import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: anatomy['size-8'],
    alignItems: 'center',
    gap: anatomy['size-24'],
  },
  personCard: {
    display: 'flex',
    width: '270px',
  },

  personDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  photo: {
    width: '40px',
    height: '40px',
    border: `1px solid ${colors.tertiaryBorder}`,
    borderRadius: '40px',
    margin: '0 8px',
  },
  firstName: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighDemi,
    lineHeight: typography['fontSizeBody-2'],
    overflow: 'hidden',
    color: colors.primaryText,
    textOverflow: 'ellipsis',
  },
  position: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['fontSizeBody-2'],
    overflow: 'hidden',
    color: colors.secondaryText,
    textOverflow: 'ellipsis',
  },

  statuses: {
    display: 'flex',
    width: '239px',
    flexDirection: 'row',
    padding: '8px',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: '24px',
    alignSelf: 'stretch',
    ':not(#__unused__) > div': {
      flex: 1,
      fontFamily: fonts.din2014Regular,
      fontSize: typography['fontSizeCaption-1'],
      fontStyle: 'normal',
      fontWeight: typography.fontWeighRegular,
      lineHeight: typography['fontSizeBody-2'],
      color: colors.secondaryText,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '24px',
    alignSelf: 'stretch',
  },
});
