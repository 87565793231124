import React, { useEffect } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import CrossIcon from '../icons/cross-icon';
import { palette } from '@shared/tokens/palette.stylex';

interface ToastProps {
  id: number;
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  onClose: (id: number) => void;
  index: number;
  duration: number;
  closable?: boolean;
  details?: string;
  onDetailsClick?: () => void;
}

const OneToast: React.FC<ToastProps> = ({
  id,
  message,
  type,
  onClose,
  index,
  duration,
  closable,
  details,
  onDetailsClick,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(id);
    }, duration + 1000);
    return () => clearTimeout(timer);
  }, [id, onClose, closable]);

  return (
    <div {...stylex.props([styles.base(index, duration), styles[type]])}>
      {message}
      {details && (
        <span {...stylex.props(styles.details)} onClick={onDetailsClick}>
          Подробнее
        </span>
      )}
      {closable && (
        <div onClick={() => onClose(id)} {...stylex.props(styles.close)}>
          <CrossIcon color={palette.greyWhite} width="24" height="24" />
        </div>
      )}
    </div>
  );
};

export default OneToast;
