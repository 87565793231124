import { ErrorPage } from '@pages/error-page';
import { BasicLayout } from '@widgets/basic-layout';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AuthPage, EventsMain, NewEvent, NotFoundPage, QrPage, UnknownErrorPage, QrPageAdmin } from '../pages';
import { PrivatePage } from './private-page';
import { EventPage } from '@pages/event';
import { MainLayout } from '@widgets/main-layout';
import { PublicPage } from './public-page';
import { ErrorPageForRemote } from '@pages/error-page-for-remote';

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<PublicPage />}>
        <Route element={<BasicLayout />}>
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/qr/:id" element={<QrPage />} />
          <Route path="/qr-moderator/:user/:event" element={<QrPageAdmin />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/error-for-remote" element={<ErrorPageForRemote />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/unknown-error" element={<UnknownErrorPage />} />
      </Route>
      <Route element={<PrivatePage />}>
        <Route element={<MainLayout />}>
          <Route path="/" element={<EventsMain />} />
          <Route path="/new-event" element={<NewEvent />} />
          <Route path="/events/:id" element={<EventPage />} />
        </Route>
      </Route>
    </Route>
  )
);
