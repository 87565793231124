import { UNKNOWN_ERROR } from '@shared/libs/errors-consts';
import { ErrorLayot } from '@shared/ui/error-layout';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const UnknownErrorPage: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/');
  };
  return <ErrorLayot error={UNKNOWN_ERROR} showBasicButton={true} navigateTo={handleNavigate} />;
};
