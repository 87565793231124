import React, { memo, useState } from 'react';
import { formatPhoneNumber } from '@entities/create-segment-modal/lib/form-validator.ts';
import { IFreelanceEmployee } from '@shared/types/types.ts';
import { XmarkIconFreelance } from '@shared/ui/x-mark-icon-freelanceEmpForm.tsx';
import { Input, TextField } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import { emailValidator } from '@pages/event/lib/validator.ts';

interface FreelanceEmployeeFormProps {
  employee: IFreelanceEmployee;
  changeEmployee: (value: IFreelanceEmployee) => void;
  onChangeDelete: (freelanceEmployees: IFreelanceEmployee[]) => void;
  freelanceEmployees: IFreelanceEmployee[];
  onEmailError?: (hasError: boolean) => void;
}

const FreelanceEmployeeForm: React.FC<FreelanceEmployeeFormProps> = memo(
  ({ employee, changeEmployee, onChangeDelete, freelanceEmployees, onEmailError }) => {
    const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

    const handleChangeNumber = (value: string) => {
      const replacedValue = String(value).replace(/\D/g, '');
      let formattedValue = '';

      if (replacedValue.length > 0) {
        // Добавляем логику для первой цифры
        if (replacedValue[0] === '7' || replacedValue[0] !== '8') {
          formattedValue = '8';
        }
        formattedValue += replacedValue;
      }
      // Обрезаем до 11 символов
      formattedValue = formattedValue.slice(0, 11);
      const maskedValue = formatPhoneNumber(formattedValue);
      const newEmployee = { ...employee, phone: maskedValue };
      changeEmployee(newEmployee);
    };

    const changeEmailHandler = async (value: string) => {
      const emailError = await emailValidator(value);
      changeEmployee({ ...employee, email: value });
      if (emailError) {
        setEmailErrorMsg(emailError);
        onEmailError?.(true);
        return;
      }
      setEmailErrorMsg('');
      onEmailError?.(false);
    };

    const deleteEmployeeHandler = (employeeId: number) => {
      const filteredEmployee = freelanceEmployees.filter((el: IFreelanceEmployee) => el.id !== employeeId);
      onChangeDelete(filteredEmployee);
    };

    return (
      <div {...stylex.props(styles.container)}>
        <form {...stylex.props(styles.form)}>
          <div {...stylex.props(styles.inputs)}>
            <span {...stylex.props(styles.flex)}>
              <Input
                value={employee.lastName}
                settings={{ width: '257px' }}
                size="small"
                changeFunction={(value: string) => changeEmployee({ ...employee, lastName: value })}
                placeholder="Введите фамилию участника"
                labelText="Фамилия *"
              />
              <Input
                value={employee.firstName}
                settings={{ width: '257px' }}
                size="small"
                changeFunction={(value: string) => changeEmployee({ ...employee, firstName: value })}
                placeholder="Введите имя участника"
                labelText="Имя *"
              />
            </span>

            <span {...stylex.props(styles.flex)}>
              <Input
                value={employee.email}
                settings={{ width: '249px' }}
                size="small"
                changeFunction={async (value: string) => await changeEmailHandler(value)}
                placeholder="Введите email участника"
                labelText="Email *"
                inputMessage={emailErrorMsg && emailErrorMsg}
                {...(emailErrorMsg && { status: 'error' })}
              />
              <Input
                value={employee.phone}
                settings={{ width: '249px' }}
                size="small"
                changeFunction={handleChangeNumber}
                placeholder="Введите телефон участника"
                labelText="Телефон *"
              />
            </span>
          </div>
          <TextField
            settings={{ width: '514px' }}
            changeFunction={(e) => changeEmployee({ ...employee, comment: e.target.value })}
            value={employee.comment}
            placeholder="Введите текст"
            labelMsg="Комментарий"
          />
        </form>
        <XmarkIconFreelance onClick={() => deleteEmployeeHandler(employee.id)} />
      </div>
    );
  }
);

export default FreelanceEmployeeForm;
