import { UserAvatar } from '@features/user-avatar';
import { ITickets } from '@pages/event/api/useFetchTickets';
import * as stylex from '@stylexjs/stylex';
import { FC, memo, useState } from 'react';
import { styles } from './styles';

import { TrashIcon } from '@shared/ui/icons/trash-icon';
import { IconButton } from '@shared/ui/icon-button'; // FIXME: перенести по fsd
import { DownloadIcon } from '@shared/ui/icons/download-icon';

interface IProps {
  ticket: ITickets;
  name: string;
  position?: string;
  photo?: string;
  onDelete?: () => void;
  getTicketQR?: () => void;
}

export const EventParticipantCard: FC<IProps> = memo(({ name, position, photo, ticket, onDelete, getTicketQR }) => {
  const [imageError, setImageError] = useState(false);
  const getInitials = (name: string): string => {
    const names = name.trim().split(' ');
    const initials = names.map((name: string) => name.charAt(0).toUpperCase());
    return initials.join('').slice(0, 2);
  };

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.personCard)}>
        {photo && !imageError ? (
          <div>
            <img {...stylex.props(styles.photo)} src={photo} alt={name} onError={() => setImageError(true)} />
          </div>
        ) : (
          <UserAvatar initials={getInitials(name)}></UserAvatar>
        )}
        <div {...stylex.props(styles.personDescription)}>
          <div {...stylex.props(styles.firstName)}>{name} </div>
          <div {...stylex.props(styles.position)}>{position}</div>
        </div>
      </div>
      <div {...stylex.props(styles.statuses)}>
        {/* <div>Приглашение отправлено</div>
        <div> {ticket.registration ? 'Приглашение принято' : 'Приглашение непринято'}</div> */}
        <div>QR-код отправлен</div>
        <div> {ticket.checked ? 'Присутствовал' : 'Не присутствовал'}</div>
      </div>
      <div {...stylex.props(styles.actions)}>
        <IconButton icon={<TrashIcon color="#DD524C" />} onClick={onDelete} />
        <IconButton icon={<DownloadIcon />} onClick={getTicketQR} />
      </div>
    </div>
  );
});
