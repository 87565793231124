import { useQuery, gql } from 'urql';

const GET_SETTINGS_BY_EVENT_ID = gql`
  query GetSettingsByEventId($eventId: Int!) {
    getSettingsByEventId(eventId: $eventId) {
      id
      eventId
      showFioInApp
    }
  }
`;

const useGetSettingsByEventId = (eventId: number) => {
  const [result] = useQuery({
    query: GET_SETTINGS_BY_EVENT_ID,
    variables: { eventId },
  });

  const { data, fetching, error } = result;

  return {
    data: data?.getSettingsByEventId,
    isLoading: fetching,
    error,
  };
};

export default useGetSettingsByEventId;
