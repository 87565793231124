import { createContext } from 'react';

export interface ConfirmProvider {
  openConfirmDialog: (
    message: string | React.ReactNode,
    header?: string,
    okBtnLabel?: string,
    cancelBtnLabel?: string
  ) => Promise<unknown>;
}

export const ConfirmContext = createContext<ConfirmProvider | undefined>(undefined);
