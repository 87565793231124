import { colors } from '@shared/tokens/colors.stylex';
import { FC } from 'react';

interface CrossIconProps {
  color?: string;
  width?: string;
  height?: string;
}

const CrossIcon: FC<CrossIconProps> = ({ color = colors.primaryIcon, width = '16', height = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="cross icon"
    >
      <path
        d="M3.57574 11.5757C3.34142 11.8101 3.34142 12.1899 3.57574 12.4243C3.81005 12.6586 4.18995 12.6586 4.42426 12.4243L3.57574 11.5757ZM12.4243 4.42426C12.6586 4.18995 12.6586 3.81005 12.4243 3.57574C12.1899 3.34142 11.8101 3.34142 11.5757 3.57574L12.4243 4.42426ZM4.42426 3.57574C4.18995 3.34142 3.81005 3.34142 3.57574 3.57574C3.34142 3.81005 3.34142 4.18995 3.57574 4.42426L4.42426 3.57574ZM11.5757 12.4243C11.8101 12.6586 12.1899 12.6586 12.4243 12.4243C12.6586 12.1899 12.6586 11.8101 12.4243 11.5757L11.5757 12.4243ZM4.42426 12.4243L12.4243 4.42426L11.5757 3.57574L3.57574 11.5757L4.42426 12.4243ZM3.57574 4.42426L11.5757 12.4243L12.4243 11.5757L4.42426 3.57574L3.57574 4.42426Z"
        fill={color}
        fillOpacity="0.7"
      />
    </svg>
  );
};
export default CrossIcon;
