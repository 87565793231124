import { useMutation } from 'urql';
import { gql } from 'urql';

export const EVENT_MUTATION = gql`
  mutation updateEvent(
    $id: ID!
    $active: Boolean!
    $name: String!
    $description: String
    $image: String
    $dateStart: String!
    $dateEnd: String!
  ) {
    updateEvent(
      id: $id
      input: {
        active: $active
        name: $name
        description: $description
        image: $image
        dateStart: $dateStart
        dateEnd: $dateEnd
      }
    ) {
      id
      name
      description
      confirmed
      active
      image
      dateStart
      dateEnd
    }
  }
`;

interface IEventResponse {
  id: number;
  name: string;
  description: string;
  confirmed: string;
  active: boolean;
  image: string;
  dateStart: string;
  dateEnd: string;
}

type IEventVariables = IEventResponse;

export const useUpdateEventMutation = () => {
  return useMutation<IEventResponse, IEventVariables>(EVENT_MUTATION);
};
