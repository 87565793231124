import { ConfirmDialog, ConfirmProps } from '@features/confirm-dialog';
import { FC, ReactNode, useState } from 'react';
import { ConfirmContext } from './confirm-context';

interface IConfirmProviderProps {
  children: ReactNode;
}

export const ConfirmProvider: FC<IConfirmProviderProps> = ({ children }) => {
  const [confirmState, setConfirmState] = useState<ConfirmProps>({
    isOpen: false,
    header: 'Подтвердите действие',
    message: '',
    okBtnLabel: 'Подтвердить',
    cancelBtnLabel: 'Отмена',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const openConfirmDialog = (
    message: string | React.ReactNode,
    header = 'Подтвердите действие',
    okBtnLabel = 'Подтвердить',
    cancelBtnLabel = 'Отмена'
  ) => {
    return new Promise((resolve) => {
      setConfirmState({
        isOpen: true,
        header,
        message,
        okBtnLabel,
        cancelBtnLabel,
        onConfirm: () => {
          resolve(true);
          setConfirmState({ ...confirmState, isOpen: false });
        },
        onCancel: () => {
          resolve(false);
          setConfirmState({ ...confirmState, isOpen: false });
        },
      });
    });
  };

  return (
    <ConfirmContext.Provider value={{ openConfirmDialog }}>
      {children}
      {confirmState.isOpen && (
        <ConfirmDialog
          isOpen={confirmState.isOpen}
          header={confirmState.header}
          message={confirmState.message}
          okBtnLabel={confirmState.okBtnLabel}
          cancelBtnLabel={confirmState.cancelBtnLabel}
          onConfirm={confirmState.onConfirm}
          onCancel={confirmState.onCancel}
        />
      )}
    </ConfirmContext.Provider>
  );
};
