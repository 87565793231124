import useFetchEventCards from '@shared/hooks/useFetchEventCards';
import * as stylex from '@stylexjs/stylex';
import React, { useEffect, useState } from 'react';
import EventCardSwitcher from '@features/event-card-switcher';
import EventCardsSort from '@features/event-cards-sort';
import { styles } from './styles';
import { useToast } from '@shared/hooks/useToast';
import { SminexIllustration } from '@shared/ui/sminex-illustration';
import { BasicButton } from '@shared/ui/basic-button';
import { useNavigate } from 'react-router-dom';

export const EventsMain: React.FC = () => {
  const { eventCards, pageSize, error, fetching } = useFetchEventCards();

  const [searchValue, setSearchValue] = useState('');
  const { showToast } = useToast();

  useEffect(() => {
    if (error) {
      showToast('Ошибка при получении данных', 'error');
    }
  }, [error]);
  // TODO: Временное решение реализации поиска на фронте, пока нет бэка
  const filteredCards = eventCards?.filter((card) => card.name.toLowerCase().includes(searchValue.toLowerCase())) || [];

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/new-event');
  };

  return (
    <>
      <div {...stylex.props(styles.container)} data-testid="events-main-container">
        <div {...stylex.props(styles.header)}>
          <div {...stylex.props(styles.title)}>Мероприятия</div>
          <EventCardsSort searchValue={searchValue} setSearchValue={setSearchValue}></EventCardsSort>
        </div>

        <div {...stylex.props(styles.cards)} data-testid="events-main-cards">
          {fetching ? (
            new Array(pageSize).fill(null).map((_, index) => <EventCardSwitcher key={index} />)
          ) : filteredCards?.length > 0 ? (
            filteredCards.map((card, index) => <EventCardSwitcher key={index} cardDetails={card} />)
          ) : (
            <div {...stylex.props(styles.content)}>
              <SminexIllustration />
              <p {...stylex.props(styles.titleMessage)}>Нет мероприятий</p>
              <p {...stylex.props(styles.subTitleMessage)}>Нажмите кнопку ниже, чтобы добавить первое мероприятие</p>
              <BasicButton onClick={handleNavigate}>Создать</BasicButton>
            </div>
          )}
        </div>
        {/* 
         Пагинация отключена, до тех пор пока на беке не сделают
       {(!fetching || !eventCards?.length) && (
         <div {...stylex.props(styles.pagination)}>
           <Pagination
             totalPage={pageCount || 0}
            changeFunction={handlePageChange}
             onNextPageClick={handleNextPageClick}
             onPreviousPageClick={handlePreviousPageClick}
            currentPage={currentPage}
            isActive={true}
          />
        </div>
     )} */}
      </div>
    </>
    // <>
  );
};
