import { UploadedData } from '@entities/add-participants-modal/with-excel';

export const uploadFile = async (file: File, eventId: number, segmentId: number): Promise<UploadedData> => {
  const url = `${import.meta.env.VITE_API_URL}/api/v1/csv/upload?eventId=${eventId}&segmentId=${segmentId}&ticketChecked=false&ticketInside=false&ticketActive=true&ticketRegistration=false`;

  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Не удалось загрузить файл');
  }

  return response.json();
};
