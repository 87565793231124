import InputSearch from '@entities/input-search';
import CreateTab from '@entities/create-tab';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import useFetchEventCards from '@shared/hooks/useFetchEventCards';

interface EventCardsSortProps {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const EventCardsSort: React.FC<EventCardsSortProps> = ({ searchValue, setSearchValue }) => {
  const navigate = useNavigate();

  const { handleSearchChange } = useFetchEventCards();

  const timerDebounceRef = useRef<ReturnType<typeof setTimeout>>();

  const handleDebounceScroll = () => {
    if (timerDebounceRef.current) {
      clearTimeout(timerDebounceRef.current);
    }
    timerDebounceRef.current = setTimeout(() => {
      handleSearchChange(searchValue);
    }, 1500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerDebounceRef.current);
    };
  }, []);

  const changeHandler = (value: string) => {
    setSearchValue(value);
    handleDebounceScroll();
  };

  return (
    <div {...stylex.props(styles.base)} data-testid="event-cards-sort">
      <InputSearch
        searchValue={searchValue}
        changeValue={(value) => changeHandler(value)}
        clearHandler={() => setSearchValue('')}
        searchHandler={() => handleSearchChange(searchValue)}
      />
      <CreateTab createHandler={() => navigate('/new-event')} />
    </div>
  );
};

export default EventCardsSort;
