import { FC } from 'react';
import * as stylex from '@stylexjs/stylex';
import { BasicButton } from '@shared/ui/basic-button';
import { SminexIllustration } from '@shared/ui/sminex-illustration';

import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { anatomy } from '@shared/tokens/anatomy.stylex';

export interface IErrorLayot {
  title: string;
  subtitle: string;
}
interface IErrorLayotProps {
  error: IErrorLayot;
  showBasicButton?: boolean;
  navigateTo?: () => void;
}

const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: anatomy['size-24'],
    height: '100vh',
    '@media (max-width: 680px)': {
      paddingTop: '53px',
    },
  },
  title: {
    fontSize: anatomy['size-44'],
    fontFamily: fonts.din2014Demi,
    color: colors.primaryInverseBackground,
    textAlign: 'center',
    '@media (max-width: 680px)': {
      fontSize: anatomy['size-36'],
    },
  },
  subTitle: {
    fontSize: '18px',
    fontFamily: fonts.din2014Regular,
    color: colors.secondaryBackground,
    textAlign: 'center',
    '@media (max-width: 680px)': {
      fontSize: anatomy['size-16'],
    },
  },
});

export const ErrorLayot: FC<IErrorLayotProps> = ({ error, showBasicButton, navigateTo }) => {
  return (
    <div {...stylex.props(styles.container)}>
      <SminexIllustration />
      <p {...stylex.props(styles.title)}>{error.title}</p>
      <p {...stylex.props(styles.subTitle)}>{error.subtitle}</p>
      {showBasicButton ? (
        <BasicButton onClick={navigateTo}>На главную</BasicButton>
      ) : (
        <div style={{ height: '40px' }} />
      )}
    </div>
  );
};
