import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import stylex from '@stylexjs/stylex';
export const styles = stylex.create({
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    overflowX: 'auto',
    padding: '0 0 20px 20px',
  },

  input: {
    padding: '20px 0 20px 0',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
  },

  sendeButtons: {
    paddingBottom: '20px',
  },
  navText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['fontSizeBody-2'],
    color: colors.secondaryText,
  },
  textDelete: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer',
  },
});
