import { useMutation } from 'urql';
import { gql } from 'urql';

interface ICreateTicketsVariables {
  userIds: number[];
  eventId: number;
  segmentId: number;
}

interface ITicket {
  id: string;
  ticketUuid: string;
  checked: boolean;
  inside: boolean;
  active: boolean;
  registration: boolean;
  createdAt: string;
  updatedAt: string;
  ticketSegmentId: string;
}

interface ICreateTicketsResponse {
  createTicket: ITicket;
}

const CREATE_TICKETS_MUTATION = gql`
  mutation ($userIds: [Int!]!, $eventId: Int!, $segmentId: Int!) {
    createTicket(
      input: { checked: false, inside: false, registration: false, active: true }
      userId: $userIds
      eventId: $eventId
      segmentId: $segmentId
    ) {
      id
      ticketUuid
      checked
      inside
      active
      registration
      createdAt
      updatedAt
      ticketSegmentId
    }
  }
`;

export const useCreateTickets = () => {
  const [result, executeMutation] = useMutation<ICreateTicketsResponse, ICreateTicketsVariables>(
    CREATE_TICKETS_MUTATION
  );

  const createTickets = (userIds: number[], eventId: number, segmentId: number) => {
    return executeMutation({
      userIds,
      eventId,
      segmentId,
    });
  };
  return { createTickets, fetching: result.fetching };
};
