import { ToastContext, ToastContextProps } from '@app/toast-context';
import { useContext } from 'react';

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within an ToastContext');
  }
  return context;
};
