import * as XLSX from 'xlsx';

export const downloadTemplate = async (): Promise<void> => {
  const response = await fetch(`${import.meta.env.VITE_API_URL}/api/v1/csv/template`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Ошибка при скачивании шаблона');
  }

  const data = await response.json();
  const headersString = data.template; // Пример: 'Фамилия;Имя;Отчество;Номер телефона;E-mail;комментарий'

  // Преобразуем строку заголовков в массив
  const headersArray = headersString.split(';');

  // Создаем объект данных для Excel
  const worksheetData = [headersArray];
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');

  // Генерируем файл Excel и инициируем его скачивание
  XLSX.writeFile(workbook, 'шаблон.xlsx');
};
