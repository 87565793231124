import { ReactNode, useState } from 'react';
import { getJWTTokens, removeJWTTokens, setJWTokens } from '@shared/libs/local-storage';
import { AuthContext, AuthContextData } from './auth-context';

interface IProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: IProviderProps) => {
  const [authState, setAuthState] = useState<AuthContextData>(getJWTTokens());

  const login = (email: string, accessToken: string = '', refreshToken: string = '') => {
    setAuthState({
      email,
      accessToken,
      refreshToken,
    });
    setJWTokens(email, accessToken, refreshToken);
  };

  const logout = () => {
    setAuthState({
      email: null,
      accessToken: null,
      refreshToken: null,
    });
    removeJWTTokens();
  };

  return <AuthContext.Provider value={{ data: authState, logout, login }}>{children}</AuthContext.Provider>;
};
