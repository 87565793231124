import { createContext } from 'react';

export interface AuthContextData {
  email: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

export interface AuthContextType {
  data: AuthContextData;
  login: (email: string, accessToken: string, refreshToken: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);
