import Toasts from '@shared/ui/toasts';
import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { ToastContext } from './toast-context';

interface ToastProps {
  id: number;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  duration: number;
  closable?: boolean;
  details?: string;
  onDetailsClick?: () => void;
}

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const showToast = (
    message: string,
    type: 'success' | 'error' | 'warning' | 'info',
    options: { duration?: number; closable?: boolean; details?: string; onDetailsClick?: () => void } = {}
  ): void => {
    const { duration = 3000, closable = false, details, onDetailsClick } = options;
    const id = Date.now();
    const newToast: ToastProps = { id, message, type, duration, closable, details, onDetailsClick };

    setToasts((prev) => [newToast, ...prev]);

    timerRef.current = setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.id !== id));
    }, duration + 1000);
  };

  const onClose = (id: number) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toasts onClose={onClose} toasts={toasts} />
    </ToastContext.Provider>
  );
};
