import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';
// import { anatomy } from '@shared/tokens/anatomy.stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.din2014Regular,
    gap: '4px',
  },

  header: {
    padding: 0,
    color: colors.primaryText,
    fontSize: '24px',
    fontFamily: fonts.din2014Regular,
    paddingBottom: '24px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '20px',
  },

  button: {},
  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },

  menuItem: {
    color: colors.primaryText,
    fontSize: '14px',
  },
});
