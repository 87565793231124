import { colors } from '@shared/tokens/colors.stylex';
import stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
// import { colors } from '@shared/tokens/colors.stylex';
// import { anatomy } from '@shared/tokens/anatomy.stylex';

export const styles = stylex.create({
  base: {
    width: '302px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.din2014Regular,
    alignItems: 'start',
  },
  imageWrapper: {
    marginTop: '24px',
    width: '302px',
    height: '212px',
    borderRadius: '8px',
    border: `1px solid ${colors.ghostBorder}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // background: `url('${img}') lightgray 0px 2.999px / 100% 95.391% no-repeat, #F6F6F6`,
  },

  editButton: {
    paddingTop: '10px',
    color: colors.accentText,
    fontSize: '12px',
    backgroundColor: 'transparent',
    border: 'none',
  },
  label: { paddingTop: '10px', cursor: 'pointer' },
});
