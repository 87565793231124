import { string, ValidationError } from 'yup';

export type IValidateValue = string | false;
export interface ILoginVariables {
  email: string;
  password: string;
}

const PASSWORD_LENGTH = 4;
const MAX_PASSWORD_LENGTH = 45;
export const EMAIL_ERROR_MESSAGE = 'Неверный адрес электронной почты';
export const PASSWORD_ERROR_MESSAGE = `Пароль должен содержать не менее ${PASSWORD_LENGTH} знаков`;
export const PASSWORD_MAX_LENGTH_MESSAGE = `Пароль должен содержать не более ${MAX_PASSWORD_LENGTH} знаков`;
export const REQURED_MAIL_MESSAGE = 'Поле «Почта» должно быть заполнено';
export const REQURED_PASSWORD_MESSAGE = 'Поле «Пароль» должно быть заполнено';
export const REQURED_LAT_MESSAGE = 'Пароль содержит не допустимые символы';
export const REGEX_SCHEMA = /^[0-9a-zA-Z!@#$%^&*.,_?:|/'\\]+$/;

export const emailValidator = async (eamil: string) => {
  let resp: IValidateValue = false;
  const eamilSchema = string()
    .default('')
    .email(EMAIL_ERROR_MESSAGE)
    .required(REQURED_MAIL_MESSAGE)
    .max(40, 'Email должен содержать не более 40 символов');
  try {
    await eamilSchema.validate(eamil);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};

export const passwordValidator = async (password: string) => {
  let resp: IValidateValue = false;
  const passwordSchema = string()
    .default('')
    .matches(REGEX_SCHEMA, REQURED_LAT_MESSAGE)
    .required(REQURED_PASSWORD_MESSAGE)
    .min(PASSWORD_LENGTH, PASSWORD_ERROR_MESSAGE)
    .max(MAX_PASSWORD_LENGTH, PASSWORD_MAX_LENGTH_MESSAGE);
  try {
    await passwordSchema.validate(password);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};
