import stylex from '@stylexjs/stylex';
import { colors } from '../../shared/tokens/colors.stylex';
import { fonts } from '../../shared/tokens/fonts.stylex';

export const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: {
      default: '64px 80px',
      '@media (max-height: 680px)': '95px 16px',
    },
    gap: '36px',
    height: {
      default: '100vh',
      '@media (max-height: 680px)': '100%',
    },
    alignSelf: 'stretch',
    overflowY: {
      default: 'visible',
      '@media (max-width: 320px)': 'auto',
    },
  },
  logo: {
    position: 'absolute',
    top: '64px',
  },
});
