import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import FreelanceEmployeeForm from '@entities/freelance-employee-form/index.tsx';
import AddSegment from '@features/add-segment/index.tsx';
import { IFreelanceEmployee } from '@shared/types/types.ts';
import { memo, useCallback } from 'react';
import './styles.css';
interface IFreelanceEmployeeProps {
  freelanceEmployees: IFreelanceEmployee[];
  onChange: (freelanceEmployees: IFreelanceEmployee[]) => void;
  onEmailError: (hasError: boolean) => void;
}

export const FreelanceEmployee: React.FC<IFreelanceEmployeeProps> = memo(
  ({ freelanceEmployees = [], onChange, onEmailError }) => {
    const addEployeeHandler = useCallback(() => {
      const newEmployee: IFreelanceEmployee = {
        id: Date.now(),
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        comment: '',
      };
      onChange([...freelanceEmployees, newEmployee]);
    }, [freelanceEmployees, onChange]);

    const changeEployeeChangeHandler = useCallback(
      (employee: IFreelanceEmployee, id: number) => {
        onChange(freelanceEmployees.map((item, i) => (i === id ? employee : item)));
      },
      [freelanceEmployees, onChange]
    );

    const hasEmptyName = freelanceEmployees.some((employee) => employee.lastName.trim() === '');
    // const hasEmptyName = freelanceEmployees.some((employee) => employee.lastName.trim() === '');
    const hasEmptyMail = freelanceEmployees.some((employee) => employee.email.trim() === '');
    const hasEmptyPhone = freelanceEmployees.some((employee) => employee.phone.trim() === '');

    const сonditionAdding = hasEmptyName || hasEmptyMail || hasEmptyPhone;
    return (
      <>
        <div className="custom-scroll">
          <div style={{ maxHeight: '400px' }}>
            {freelanceEmployees.map((employee, index) => (
              <FreelanceEmployeeForm
                key={index}
                employee={employee}
                changeEmployee={(employee) => changeEployeeChangeHandler(employee, index)}
                onChangeDelete={onChange}
                freelanceEmployees={freelanceEmployees}
                onEmailError={onEmailError}
              />
            ))}
          </div>
        </div>
        {сonditionAdding && (
          <div {...stylex.props(styles.reminderFreeForm)}>Все обязательные поля должны быть заполнены!</div>
        )}
        <div {...stylex.props(styles.addSegmentBtn)}>
          <AddSegment text="Добавить участника" onClick={addEployeeHandler} width="auto" />
        </div>
      </>
    );
  }
);
