import { FC, useEffect, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { Button } from '@sminex/sminex-uikit';
import SortTriangleIcon from '@shared/ui/icons/sort-triangle-icon';
import { styles } from './styles';
import { SmsResult } from '@pages/event/api/useFetchSms';

interface IModalProps {
  closeHandle: () => void;
  smsRecipients: SmsResult[];
}

export const ModalStatus: FC<IModalProps> = ({ closeHandle, smsRecipients }) => {
  const [showErrorUsers, setShowErrorUsers] = useState(false);
  useEffect(() => {
    smsRecipients;
  }, [smsRecipients]);

  const errors = smsRecipients.filter((recipient) => recipient.error);
  const userError = errors.filter((el) => el.error);

  const existingUsers = smsRecipients.filter((recipient) => recipient.error == null).length;

  const totalUsers = smsRecipients.length; // Общее число пользователей
  const recognizedUsers = existingUsers; // Успешно распознанные
  const unrecognizedUsers = errors.length;
  const getWidth = (value: number): number => {
    if (totalUsers * 1 === 0) return 0;
    const percentage = (value / totalUsers) * 100;
    const maxWidth = 138;
    return Math.round((maxWidth / 100) * percentage);
  };

  const getPercent = (value: number): number => {
    if (totalUsers * 1 === 0) return 0;
    return Math.round((value / totalUsers) * 100);
  };
  const handleBackClick = () => {
    setShowErrorUsers(false); // Возвращаемся к обычному виду
  };

  return (
    <>
      {!showErrorUsers && (
        <div {...stylex.props(styles.contactTypeStatic)}>
          <p {...stylex.props(styles.userSelect)}>Тип приглашения СМС и Email</p>
        </div>
      )}
      {!showErrorUsers ? (
        <div {...stylex.props(styles.base)}>
          <div {...stylex.props(styles.section)}>
            <div {...stylex.props(styles.infoSection)}>
              <div {...stylex.props(styles.infoText)}>
                <div {...stylex.props(styles.text, styles.firstText)}>Отправлено</div>
                <div {...stylex.props(styles.text, styles.middleText)}>Успешно</div>
                <div {...stylex.props(styles.text, styles.lastText)}>Не доставлено</div>
              </div>
              <div {...stylex.props(styles.infoValue)}>
                <div {...stylex.props(styles.accepted)}>{totalUsers ? totalUsers : '1'}</div>
                <div {...stylex.props(styles.notAccepted)}>{recognizedUsers ? recognizedUsers : '1'}</div>
                <div {...stylex.props(styles.unrecongnized)}>{unrecognizedUsers}</div>
              </div>
              <div {...stylex.props(styles.open)} onClick={() => setShowErrorUsers(true)}>
                показать
              </div>
            </div>
          </div>
          <div {...stylex.props(styles.infoWidget)}>
            <div {...stylex.props(styles.progressBarContainer)}>
              <div {...stylex.props(styles.progressBar, styles.total(getWidth(totalUsers)))}>100%</div>
            </div>
            <div {...stylex.props(styles.progressBarContainer)}>
              <div {...stylex.props(styles.progressBar, styles.recognized(getWidth(recognizedUsers)))}>
                {getPercent(recognizedUsers)}%
              </div>
            </div>
            <div {...stylex.props(styles.progressBarContainer)}>
              <div {...stylex.props(styles.progressBar, styles.unrecognized(getWidth(unrecognizedUsers)))}>
                {getPercent(unrecognizedUsers)}%
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div {...stylex.props(styles.userTable)}>
          {/* <div {...stylex.props(styles.scrollContainer)}> */}
          {errors ? (
            <div>
              <div {...stylex.props(styles.contactType)}>
                <p {...stylex.props(styles.userSelect)}>Тип приглашения СМС и Email</p>
                <div {...stylex.props(styles.scrollContainer)}>
                  <div {...stylex.props(styles.contactHeader, styles.userTableText)}>
                    <div {...stylex.props(styles.contactInfo)}>
                      <p {...stylex.props(styles.contactItem)}>
                        Тип <SortTriangleIcon />
                      </p>
                    </div>
                    <div {...stylex.props(styles.contactInfo)}>
                      <p {...stylex.props(styles.contactItem)}>
                        E-mail <SortTriangleIcon />
                      </p>
                    </div>
                    <div {...stylex.props(styles.contactInfo)}>
                      <p {...stylex.props(styles.contactItem)}>
                        Телефон <SortTriangleIcon />
                      </p>
                    </div>
                  </div>
                  <div {...stylex.props(styles.userTable)}>
                    {userError.map((user) => (
                      <div key={user.id} {...stylex.props(styles.userList)}>
                        <div {...stylex.props(styles.userName)}>
                          <p {...stylex.props(styles.userNameItem)}>Смс/Mail</p>
                        </div>
                        <div {...stylex.props(styles.userEmail)}>
                          <p {...stylex.props(styles.userEmailItem)}>{user.email}</p>
                        </div>
                        <div {...stylex.props(styles.userPhone)}>
                          <p {...stylex.props(styles.userPhoneItem)}>{user.phoneNumber}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Нет данных о нераспознанных пользователях</p>
          )}
        </div>
      )}
      <div {...stylex.props(styles.button)}>
        {showErrorUsers && (
          <Button
            size="small"
            settings={{
              width: '96px',
              fontFamily: 'DIN-2014_Regular',
            }}
            variant="secondary"
            onClick={handleBackClick}
          >
            Назад
          </Button>
        )}
        <Button
          size="small"
          settings={{
            width: '96px',
            fontFamily: 'DIN-2014_Regular',
          }}
          variant="accent"
          onClick={closeHandle}
        >
          Закрыть
        </Button>
      </div>
    </>
  );
};
