import { Chevron, ReversedChevron } from '@shared/ui/chevron';
import * as stylex from '@stylexjs/stylex';
import { FC, useState } from 'react';
import { styles } from './styles';

interface IAccordionProps {
  element?: React.ReactNode;
  children: React.ReactNode;
  onToggle?: (isOpen: boolean) => void;
}

export const Accordion: FC<IAccordionProps> = ({ element, children, onToggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openHndler = () => {
    onToggle && onToggle(!isOpen);
    setIsOpen(!isOpen);
  };
  return (
    <div {...stylex.props(styles.mainContainer)}>
      <div {...stylex.props(styles.parentContainer)}>
        <div {...stylex.props(styles.leftContainer)}>
          <button onClick={openHndler} {...stylex.props(styles.icon)}>
            {isOpen ? <ReversedChevron /> : <Chevron />}
          </button>
          {element}
        </div>
      </div>
      {isOpen && children ? <div {...stylex.props(styles.childContainer)}>{children}</div> : null}
    </div>
  );
};
