import { useAuth } from '@features/auth/hooks/useAuth';
import * as stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { styles } from './styles';
import LogoutIcon from './logout-icon';

export const LogoutButton: FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const clickHandle = () => {
    logout();
    navigate('/auth');
  };
  return (
    <a onClick={clickHandle} {...stylex.props(styles.link)}>
      <div {...stylex.props(styles.content)}>
        Выйти <LogoutIcon />
      </div>
    </a>
  );
};
