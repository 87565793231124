import { Button, Input, Modal } from '@sminex/sminex-uikit';
import { createPortal } from 'react-dom';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { FC, useEffect, useRef, useState } from 'react';
import { phraseValidator } from '@pages/event/lib/validator';

interface IModalProps {
  active: boolean;
  closeHandle: () => void;
  addSegment: (segment: string) => void;
}

export const ModalAddSegment: FC<IModalProps> = ({ active, closeHandle, addSegment }) => {
  const [segmentName, setSegmentName] = useState('');
  const inputReference = useRef<HTMLInputElement>(null);
  const [segmentNameErrors, setSegmentNameErrors] = useState<string | false>(false);

  useEffect(() => {
    if (segmentName) {
      phraseValidator(segmentName).then(setSegmentNameErrors);
    }
  }, [segmentName]);

  useEffect(() => {
    inputReference.current && inputReference.current.focus();
    setSegmentName('');
  }, [active]);

  const handleClick = () => {
    if (segmentName.length < 4) {
      setSegmentNameErrors('Сегмент должен содержать не менее 4 символов.');
      return;
    }
    if (segmentName.length > 40) {
      setSegmentNameErrors('Сегмент должен содержать не больше 40 символов.');
      return;
    }
    if (!segmentName) {
      phraseValidator(segmentName).then(setSegmentNameErrors);
      return;
    }
    addSegment(segmentName);
  };
  if (!active) return;
  return createPortal(
    <Modal active={active} settingsContainer={{ height: 'auto', width: '600px' }}>
      <div {...stylex.props(styles.container)}>
        <p {...stylex.props(styles.header)}>Добавить сегмент </p>
        <div {...stylex.props(styles.input)}>
          <Input
            inputMessage={segmentNameErrors || ''}
            status={segmentNameErrors ? 'error' : undefined}
            settings={{ width: 'auto' }}
            value={segmentName}
            ref={inputReference}
            changeFunction={setSegmentName}
            labelText="Сегмент *"
          />
        </div>
        <div {...stylex.props(styles.actions)}>
          <Button onClick={() => closeHandle()} variant="secondary">
            Отмена
          </Button>
          <Button onClick={handleClick} variant="accent">
            Добавить
          </Button>
        </div>
      </div>
    </Modal>,
    document.getElementById('modal') as Element
  );
};
